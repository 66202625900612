<template>
  <div class="home">
    <s-title :text="$t('dashboard')" />
  </div>
</template>

<script>
export default {
  name: 'Dashboard'
}
</script>
